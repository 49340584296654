.filter {
    text-align: center;
    padding: 40px 20px 20px;

    span {
        @include price;
        line-height: 25px;
        font-weight: 500;
        letter-spacing: 0.075em;
    }

    &__buttons {
        padding-top: 10px;

        @include breakpoint(small only) {
            display: inline-block;
        }

        .button {
            @include smallest;
            text-align: left;
            color: get-color('dark-grey');
            min-width: 0;
            padding: 0 0 0 5px;
            margin: 0 30px;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: 0.15em;
            border: none;

            &:hover {
                background-color: transparent;
                background: url('img/wave-dark-grey.svg') no-repeat left bottom;
                background-size: 67px 5px;
                transition: none;
            }

            &.on {
                background: url('img/wave-dark-grey.svg') no-repeat left bottom;
                background-size: 67px 5px;
            }
        }
    }
}

.selectric {
    border: none;
    background-color: transparent;
    color: get-color('dark-grey');
    height: 11px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 5px;
        width: 6px;
        height: 8px;
        background: url('img/select-arrow-grey.svg') no-repeat center right;
    }

    b.button {
        background-color: transparent;
    }

    .label {
        @include smallest;
        text-align: left;
        color: get-color('dark-grey');
        line-height: 1;
        font-weight: 700;
        letter-spacing: 0.15em;
    }
}