.standard-content-new {
    position: relative;
    padding: 70px 20px 140px;

    @include breakpoint(small only) {
        padding-top: 50px;
    }

    &__wrapper {
      width: 100%;
      margin-bottom: 105px;
    }

    &__text {

        margin: auto;

        h2 {
            @include large-h2;
            color: get-color('dark-grey');
        }

        h3 {
            color: get-color('dark-grey');
            font-size: 16px;
            line-height: 25px;
            font-family: $primary-font;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        p {
            @include body-copy;
            color: get-color('dark-grey');
            margin-bottom: 30px;

            a {
                color: get-color('dark-grey');
            }
        }

        ol,ul {
            list-style: none;
            margin: 0 0 42px 30px;

            li {
                position: relative;
                margin-bottom: 30px;
                padding-left: 30px;
                font-weight: 700;
                font-size: 15px;
                letter-spacing: .025em;
                line-height: 1.25;
                color: get-color('dark-grey');

                &::before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background-color: #C1C3C3;
                }

                a {

                }
            }
        }

        &-cta {
            margin-top: 30px;
            
            
        }
    }

    &__image {

        margin-bottom: 42px;

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
    
    @include spa() {
        .standard-content-new {
            &__text h2 {
                color: get-color('dark-blue');
            }
            
            &__text-cta .button--primary {
                color: #fff;
                background-color: get-color('dark-blue');
                border-color: get-color('dark-blue');
                
                &:hover {
                    color: get-color('dark-blue');
                    background-color: #fff;
                }
            }
        }
    }
    
    @include country-park() {
        .standard-content-new {
            &__text h2 {
                color: get-color('dark-green');
            }
            
            &__text-cta .button--primary {
                color: #fff;
                background-color: get-color('mid-green');
                border-color: get-color('mid-green');
                
                &:hover {
                    color: get-color('mid-green');
                    background-color: #fff;
                }
            }
        }
    }
    
    @include weddings-events() {
        .standard-content-new {
            &__text h2 {
                color: get-color('heather');
            }
            
            &__text-cta .button--primary {
                color: #fff;
                background-color: get-color('dark-heather');
                border-color: get-color('dark-heather');
                
                &:hover {
                    color: get-color('dark-heather');
                    background-color: #fff;
                }
            }
        }
    }
}
