.estate-map {
	position: relative;
	padding: 20px 0 90px;
	background: linear-gradient(to bottom, get-color('mid-green') 0%, get-color('green') 100%);
	
	@include breakpoint(medium) {
		padding-bottom: 85px;
		
		&::before {
			content: '';
			position: absolute;
			z-index: 10;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			pointer-events: none;
			background: url('img/estate-map-tree.svg') no-repeat left bottom;
		}
	}
	
	&__image {
		@include breakpoint(small only) {
			margin-bottom: 40px;
		}
		
		@include breakpoint(medium) {
			padding: 30px;
		}
		
		a {
			display: block;
			overflow: hidden;
			width: 100%;
			border-radius: 100%;
		}
	}
	
	&__content {
		color: #fff;
		@include flex-align-center();
		text-align: left;
		
		@include breakpoint(medium) {
			padding: 0 0 0 80px;
		}
		
		h2 {
			@include large-h2();
			margin-bottom: 20px;
			
			a {
				color: inherit;
			}
		}
		
		p {
			@include body-copy();
		}
	}
	
	@include weddings-events() {
		background: linear-gradient(to bottom, get-color('dark-heather') 0%, get-color('heather') 100%);
	
		.button.button--green-primary {
			background-color: get-color('dark-heather');
	
			&:hover {
				color: get-color('dark-heather');
				border-color: get-color('dark-heather');
				background-color: #fff;
			}
		}
	}
	
	@include spa() {
		background: linear-gradient(to bottom, get-color('dark-blue') 0%, get-color('blue') 100%);
	
		.button.button--green-primary {
			background-color: get-color('dark-blue');
	
			&:hover {
				color: get-color('dark-blue');
				border-color: get-color('dark-blue');
				background-color: #fff;
			}
		}
	}
}

