.whats-on-landing {
    @include light-blue-gradient;
    position: relative;
    
    

    @include breakpoint(small only) {
        padding-bottom: 60px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url('img/estate-map-tree.svg');
        background-repeat: no-repeat;
        width: 100%;
        height: 1000px;
        transform: scaleX(-1);
        z-index: 0;
        pointer-events: none;
    }

    &__inner {

        &-wrap {

        }
    }

    &__grid {
        width: 100%;
        padding-bottom: 60px;

        @include breakpoint(small only) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &.right-align {

            @include breakpoint (medium) {
                justify-content: flex-end;
            }

            .whats-on-landing__item {

                &:last-of-type {

                    @include breakpoint (medium) {
                        margin-top: 0px !important;
                    }
                }
            }
        }
    }

    &__item {
        margin-bottom: 0px;
        z-index: 1;
        
        @include breakpoint(small only) {
            width: 100% !important;
            margin-bottom: 70px;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 0;
        }

        /*&:first-of-type {

            .whats-on-landing__content {
                padding-left: 4%;
            }
        }*/

        &:nth-child(even) {

            @include breakpoint(medium) {
                margin-top: -10%;
                align-content: flex-start;
            }
            
            @include breakpoint(large) {
                margin-top: -16%;
                transform: translateX(24px);
            }
        }

        &:nth-child(odd) {
            @include breakpoint(medium) {
                margin-top: 5%;
            }
        }
    }

    &__featured {
        padding: 64px 0 0;

        @include breakpoint(small only) {
          margin: 0 0 70px;
        }
            
        .whats-on-landing__content {
          @include breakpoint(medium) {
            padding-top: 90px;
            padding-left: 30px;
          }
        }
    }
    
    &__image {
        height: fit-content;
        margin-bottom: 30px;

        @include breakpoint(small only) {
            margin: 0 0 30px !important;
            width: 100% !important;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {

        @include breakpoint(small only) {
            margin: 0 !important;
            width: 100% !important;
        }

        h2 {
            color: get-color('dark-blue');
            @include large-h2;
            margin-bottom: 20px;

            a {
                color: inherit;
            }
        }

        p {
            @include body-copy;
            color: get-color('black');
            padding-right: 20%;
        }
        .wave-img {
            margin-bottom: 20px;
        }

        .button {
          min-width: 128px;

          &:first-child {
            margin-right: 20px;
          }
        }
    }

    &__post-date {
        color: get-color('dark-blue');
        font-size: 16px;
        line-height: 25px;
        font-family: $primary-font;
        margin-bottom: 20px;
    }


    .pagination {
        
        a {
            color: get-color('dark-blue');
        }

        &-previous {
            
            a {
               
                &::after {
                    background-image: url('img/bullet-list-arrow-left-dark-blue.svg');
                }
            }
        }

        &-next {
            
            a {
                
                &::after {
                    background-image: url('img/bullet-list-arrow-dark-blue.svg');
                }
            }
        }
    }
}