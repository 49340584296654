.footer {
	position: relative;
	
	&.no-tree .tree {
		display: none;
	}
	
	&.solid-tree .tree {
		background-color: get-color('pale-grey');
	}

	body.template-whats-on & .tree {
		background-color: get-color('light-blue');
	}
	
	&__newsletter {
		background-color: get-color('pale-grey');
		padding: 48px 0;
		
		@include breakpoint(medium only) {
			padding-right: 40px;
		}
		
		&-content {
			h2 {
				@include h1;
				font-size: 36px;
				margin-bottom: 10px;
				color: get-color('dark-grey');

				@include breakpoint(medium) {
					padding-top: 19px;
				}

				@include breakpoint(medium down) {
					font-size: 38px;
				}
			}
			
			p {
				@include bullet-list;
				color: get-color('dark-grey');
			}
		}
		
		&-form {
			padding-top: 20px;

			@include breakpoint(medium only) {
				padding-left: 25px;
			}
			
			@include breakpoint(large) {
				padding-left: 50px;
			}
		}
		
		.form {
			input[type="email"] {
				margin-bottom: 25px;
				
				@include placeholder() {
					color: inherit;
					opacity: 1;
				}
			}
			
			button[type="submit"] {
				width: 143px;
				height: 49px;
				border: 0;
				background-color: #fff;
				font-family: $primary-font;
				text-transform: uppercase;
				font-weight: bold;
				letter-spacing: 0.1em;
				font-size: 10px;
				transition: all 0.3s ease-in-out;
				color: get-color('black');
				cursor: pointer; 
				text-transform: uppercase;
				
				@include breakpoint(medium only) {
					width: 90%;
				}
				
				@include breakpoint(medium) {
					margin-left: 18px;
				}
				
				&:hover,
				&:active {
					color: #fff;
					background-color: get-color('dark-grey');
				}
			}

			.footer__newsletter-checkbox {
				position: relative;
				transform: translateY(-9px);

				@include breakpoint(small only) {
					transform: translateY(0);
					margin-top: 21px;
				}

				#newsletter-consent-error {
					padding-left: 0;
					padding-top: 20px;

					&::before {
						content: none;
					}
				}
				
			}

            input[type="checkbox"] {
                position: absolute; 
                opacity: 0;
                top: 0px;
                z-index: 1;
                
                ~ label {
                    position: relative;
                    padding-left: 28px;
                    padding-top: 1px;
                    cursor: pointer; 
                    line-height: 1 !important;
                    display: block;
                    @include smallest();
                    font-size: 10px;
                    color: #fff;
                    font-weight: 700;
                    
                    &::before,
                    &::after {
                        content: '';
                        position: absolute; 
                    }
                    
                    &::before {
                        top: -3px;
                        left: 0;
                        width: 19px;
                        height: 19px;
                        border: 1px solid;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                    
                    &::after {
                        content: '✓';
                        color: #000;
                        font-size: 12px;
                        padding: 3px 0 0 1px;
                        top: -4px;
                        left: 4px;
                        width: 14px;
                        height: 14px;
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out;
                    }
                    
                    a {
                        color: inherit;
                        text-decoration: underline;
                        transition: opacity 0.3s ease-in-out;
                        
                        &:hover,
                        &:active {
                            opacity: 0.7;
                        }
                    }
                }
                
                &:checked ~ label {
                    &:after {
                        opacity: 1;  
                    }
                }
            }

		}
	}
	
	&__main {
		background-color: #fff;
		padding: 34px 0 60px;
		
		@media screen and (min-width: $offcanvas-breakpoint) {
			padding: 37px 0;
		}
	}
	
	&__logo {
		text-align: center;
		margin-bottom: 17px;
		
		@include breakpoint(large) {
			text-align: right;
			margin: 0;
		}
		
		img,
		svg {
			max-width: 102px;
		}
	}
	
	&__nav {
		text-align: center;
		padding-top: 10px;
		
		@include breakpoint(large) {
			padding-bottom: 84px;
		}
		
		ul {
			margin: 0 0 20px;
			list-style-type: none;
			
			@include breakpoint(large) {
				margin: 0;
			}
			
			li {
				margin: 0 0 15px;
				
				@include breakpoint(large) {
					display: inline;
					margin: 0 14px;
				}
			}
		}
		
		a {
			position: relative;
			padding-bottom: 10px;
			@include smallest();
			letter-spacing: 0.15em;
			color: get-color('dark-grey');
			transition: color 0.3s ease-in-out;
			white-space: nowrap;
			
			&::after {
				content: '';
				position: absolute;
				bottom: 0px;
				left: 0;
				width: 0;
				height: 5px;
				background: url('img/top-nav-line-green.svg');
				background-size: 67px 5px;
				transition: width 0.3s ease-in-out;
			}
			
			&:hover {
				color: get-color('green');
				
				&::after {
					width: 67px;
				}
			}
		}
	}
	
	&__social {
		text-align: center;
		
		@include breakpoint(large) {
			text-align: left;
			border-left: 1px solid rgba(#626366, 0.6);
			padding: 15px 0 0 25px;
		}
		
		ul {
			margin: 0;
			list-style-type: none;
			
			li {
				display: inline;
				margin: 0 5px;
				
				@include breakpoint(large) {
					margin: 0 10px;
				}
			}
		}
		
		path,
		circle {
			fill: get-color('dark-grey');
			transition: fill 0.3s ease-in-out;
		}
		
		a:hover {
			path,
			circle {
				fill: get-color('green');
			}
		}
	}
	
	&__address {
		text-align: center;
		
		@include breakpoint(medium down) {
			padding: 20px 40px 0;
		}
		
		@include breakpoint(large) {
			transform: translateY(-70px);
			margin-bottom: -100px;
		}
		
		p {
			font-size: 11px;
			letter-spacing: 0.02em;
			
			a {
				color: inherit;
				transition: color 0.3s ease-in-out;
				
				&:hover,
				&:active {
					color: get-color('green');
				}
			}
		}
	}
	
	&__credit {
		
		@include breakpoint(large) {
			padding-top: 20px;
		}
		
		a {
			font-size: 10px;
			letter-spacing: 0.15em;
			text-transform: uppercase;
			color: get-color('dark-grey');
			transition: color 0.3s ease-in-out;
			
			&:hover,
			&:active {
				color: get-color('green');
			}
		}
	}
}