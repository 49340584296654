.off-canvas {
	z-index: 50000;
	width: 100%;
	transform: translateX(-100%);
	background: linear-gradient(to bottom, get-color('dark-green') 0%, get-color('green') 100%);
	
	&.is-open {
		transform: translateX(0);
	}
	
	&__inner {
		padding: 20px 20px 180px 20px !important;
		position: relative;
		
		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 5%;
			width: 330px;
			height: 355px;
			background: url(img/home-country-park-bg.svg) right bottom no-repeat;
			background-size: contain;
			z-index: -1;
		}

		@include breakpoint(large) {
			padding: 35px 53px;
		}

		.tree {
			top: auto;
			bottom: 0;
		}
	}
	
	&__header {
		position: relative;
		margin-bottom: 90px;
		padding: 0 20px;
	}
	
	&__close {
		position: absolute;
		top: 0;
		left: 0;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-white.svg') no-repeat;
		background-size: 21px 21px;
		cursor: pointer;
		text-indent: -12345px;
		
		&:focus,
		&:active {
			outline: none;
		}
	}

	&__main {

		a {
			color: #fff;
		}

		ul {
			list-style: none;
		}
	}

	&__nav {
		margin-bottom: 20px;

		&-primary {


			ul {

				li {
					margin-bottom: 20px;
					padding-right: 50px;

					a {
						position: relative;
						@include h1;
						
						&::after {
							content: '';
							position: absolute;
							top: 50%;
							right: -60px;
							background-image: url('img/bullet-list-arrow-white.svg');
							background-position: center right;
							background-size: 50px 8px;
							background-repeat: no-repeat;
							width: 100%;
							height: 8px;
							opacity: 0;
							transition: opacity 0.3s ease-in-out;
						}

						@include breakpoint(medium down) {
							font-size: 35px;
							line-height: 38px
						}
						
						@include breakpoint(large) {
							font-size: 40px;
						}

						&:hover {
							&::after {
								opacity: 1;
							}
						}
					}

					&.open > a::after {
						opacity: 1;
					}
				}
			}
		}
		
		&-sub {
			display: none;
			margin-left: 50px;

			@include breakpoint(medium down) {
				margin-left: 30px;
			}
			
			li {
				margin-bottom: 0 !important;
				
				a {
					font-size: 25px !important;

					@include breakpoint(medium down) {
						font-size: 20px;
						line-height: 24px
					}
				}
			}
		}

		&-header {
			margin-bottom: 20px;
			padding-top: 20px;

			ul {

				li {
					display: inline-block;
					margin-right: 30px;

					&:last-of-type {
						margin-right: 0;
					}

					a {
						position: relative;
						text-transform: uppercase;
						@include smallest;
						font-weight: 700;
						letter-spacing: 0.15em;
						padding-bottom: 10px;
						white-space: nowrap;
						
						&::after {
							content: '';
							position: absolute;
							bottom: 0px;
							left: -3px;
							width: 0;
							height: 5px;
							background: url('img/top-nav-line-white.svg');
							background-size: 67px 5px;
							transition: width 0.3s ease-in-out;
						}
						
						&:hover::after {
							width: 67px;
						}
					}
				}
			}
		}

		&-social {

			ul {

				li {
					display: inline-block;
					margin-right: 20px;

					&:last-of-type {
						margin-right: 0;
					}

					a {
						path {
							fill: #fff;
							transition: fill 0.3s ease-in-out;
						}
						
						&:hover path {
							fill: get-color('pale-grey');
						}
					}
				}
			}
		}
	}
		
	&__offer {
		max-width: 339px;
		
		@include breakpoint(small only) {
			margin-bottom: 60px;
		}

		&-image {
			margin-bottom: 30px;
		}

		&-content {
			
			h3 {
				@include large-h2;
				color: #fff;
				margin-bottom: 20px;
			}

			.multiple-buttons {
				text-align: left;
			}
		}

		&-price {

			p {
				color: #fff;
				@include price;
				margin-bottom: 20px;
				font-size: 14px;
				
				em {
					font-size: 24px;
				}
			}
		}
	}
}