@charset 'utf-8';

$offcanvas-breakpoint: 960px;

$foundation-palette: (
	primary: #000,
	secondary: #000,
	success: #000,
	warning: #000,
	alert: #000,
	'black': #16212D,
	'white': #fff,
	
	'dark-blue': #586D7D,
	'blue': #7E9DB3,
	'light-blue': #B0D6E7,

	
	'dark-heather': #715D64,
	'heather': #A88E91,
	
	'dark-green': #6F7F51,
	'mid-green': #465E2B,
	'pale-green': #A8B793,
	'green': #A5BB5C,
	'light-green': #DAE89D,
	
	'dark-grey': #5A5A5A,
	'grey': #9D9D9D,
	'pale-grey': #DADAD9,
	'intro-grey': #f4f4f4
);

$body-background: #fff;

$primary-font: "myriad-pro", sans-serif;
$tertiary-font: "chaloops", sans-serif;


$grid-container: 1300px;
$grid-container-padding: (
	small: 40px
);

$grid-margin-gutters: 25px;
