// DIY notification:
.cookie-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20000;
	padding: 30px 0 33px;
	display: none;
	background-color: #fff;

	&__inner {
		max-width: 1260px;
		margin: 0 auto;

		.cell {
			position: relative;

			@include breakpoint(small only) {
				padding: 0 20px;
			}
		}

		p {
			margin: 0 160px 0 0;
			@include body-copy();

			@include breakpoint(small only) {
				margin: 0 0 60px;
			}

			a {
				color: inherit;
				text-decoration: underline;
				transition: color 0.3s ease-in-out;

				&:hover {
					//color: get-color('red');
				}
			}
		}
	}

	&__accept {
		position: absolute !important;
		top: 0;
		right: 0;
		height: 35px;
		color: #fff;

		@include breakpoint(small only) {
			top: initial;
			right: initial;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
		}
	}
}
