.tree {
	position: absolute;
	z-index: 100;
	left: 50%;
	top: -76px;
	width: 152px;
	height: 76px;
	border-radius: 152px 152px 0 0;
	background-color: rgba(get-color('pale-grey'), 0.35);
	transform: translateX(-50%);
	
	@include breakpoint(large) {
		width: 222px;
		height: 111px;
		top: -111px;
		border-radius: 222px 222px 0 0;
	}
	
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 52px;
		width: 48px;
		height: 48px;
		background: url('img/tree.svg') no-repeat center bottom;
		background-size: contain;
		animation: wobble-hor-bottom 15s 0s infinite both;
		
		@include breakpoint(large) {
			left: 72px;
			width: 72px;
			height: 72px;
		}
	}
}


//https://animista.net/play/attention/wobble
/* ----------------------------------------------
 * Generated by Animista on 2022-3-18 16:47:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */

@keyframes wobble-hor-bottom {
  0%,
  100% {
			transform: translateX(0%);
			transform-origin: 50% 10%;
  }
  4% {
			transform: translateX(-10px) rotate(-6deg);
  }
  6% {
			transform: translateX(7px) rotate(6deg);
  }
  8% {
			transform: translateX(-7px) rotate(-3.6deg);
  }
  10% {
			transform: translateX(4px) rotate(2.4deg);
  }
  12% {
			transform: translateX(-3px) rotate(-1.2deg);
  }
}
