.featured-carousel {
  &__carousel {
    background: linear-gradient(to bottom, get-color('dark-green') 0%, get-color('light-green') 100%);
    padding: 43px 20px 53px;
    margin-bottom: 33px;

    @include spa {
      @include blue-gradient();
    }
    
    @include weddings-events {
      @include heather-gradient();
    }
    
    @media screen and (min-width: 1246px) {
      padding: 57px 0;
      margin-bottom: 56px;
    }
  }
    
  &__inner {
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
  }

  &__item {
    @include breakpoint(medium) {
      padding: 0 23px;
    }

    a {
      position: relative;
      display: block;
      

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 10;
        outline: 5px solid transparent;
        outline-offset: -5px;
        transition: outline-color 0.3s ease-in-out;
      }
      
      &.current,
      &:hover,
      &:active {
        &::before {
          outline-color: #fff;
        }
      }
      
      span {
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 20px 5px;
        font-size: 28px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        text-align: center;
        background: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, 0.7));
        color: #fff;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 0 90px;

    @include breakpoint(medium) {
      padding: 0 20px 140px;
    }

    &-item {
      display: none;

      &.show {
        display: flex;
      }

      &-image {
        @include breakpoint(large) {
          padding-left: 66px;
        }
      }
      
      &-text {
        padding: 23px 20px 0;
        
        @include breakpoint(medium) {
          padding: 0 0 0 62px;
          @include flex-align-center;
          text-align: left;
        }
        
        h2 {
          @include large-h2();
          margin-bottom: 20px;
          color: get-color('dark-green');

          @include weddings-events() {
            color: get-color('dark-heather');
          }

          @include spa() {
            color: get-color('blue');
          }
          
          a {
            color: inherit;
          }
        }
        
        h3 {
          @include price();
          color: get-color('dark-green');

          @include weddings-events() {
            color: get-color('dark-heather');
          }

          @include spa() {
            color: get-color('blue');
          }
        }
        
        p {
          @include body-copy();
        }
      }

      &-cta {
        .button {
          @include spa() {
						background-color: get-color('dark-blue') !important;
						border-color: get-color('dark-blue') !important;
	
						&:hover {
							color: get-color('dark-blue') !important;
							background-color: #fff !important;
						}
					}

          @include weddings-events() {
            background-color: get-color('dark-heather') !important;
						border-color: get-color('dark-heather') !important;
	
						&:hover {
							color: get-color('dark-heather') !important;
							background-color: #fff !important;
						}
          }
				}
      }
      
      &-header {
        &.animate-line::after {
          background-image: url('img/line-dark-green.svg');
          
          @include weddings-events() {
            background-image: url('img/wave-dark-heather.svg');
          }
          
          @include spa() {
            background-image: url('img/wave-dark-blue.svg');
          }
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    width: 50px;
    height: 40px;
    background: no-repeat center;
    background-size: 50px 8px;
    cursor: pointer;
    text-indent: -12345px;
    bottom: -43px;
    transition: transform 0.3s ease-in-out;

    @media screen and (min-width: 1246px) {
      bottom: calc(50% - 20px);
    }
    
    &:hover,
    &:active {
      transform: scaleX(1.2);
    }
    
    &.slick-prev {
      left: calc(50% - 75px);
      transform-origin: right center;
      background-image: url('img/slider-prev-white.svg');

      @media screen and (min-width: 1246px) {
        left: -55px;
      }
    }
    
    &.slick-next {
      right: calc(50% - 75px);
      transform-origin: left center;
      background-image: url('img/slider-next-white.svg');

      @media screen and (min-width: 1246px) {
        right: -55px;
      }
    }
  }
}