.landing {
	position: relative;
	padding-top: 110px;
	margin-bottom: 50px;
	
	@include breakpoint(small only) {
		padding-top: 60px;
	}
	
	&__slider {
		a,
		img {
			display: block;
		}

		.slick-arrow {
			position: absolute;
			z-index: 10;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			bottom: -30px;
			transition: transform 0.3s ease-in-out;
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: calc(50% - 75px);
				transform-origin: right center;
				background-image: url('img/slider-prev-dark-grey.svg');

				@include spa() {
					background-image: url('img/slider-prev-blue.svg');
				}
				
				@include weddings-events() {
					background-image: url('img/slider-prev-heather.svg');
				}
				
				@include country-park() {
					background-image: url('img/slider-prev-green.svg');
				}
				
				@include breakpoint(medium) {
					left: 0;
				}
			}
			
			&.slick-next {
				right: calc(50% - 75px);
				transform-origin: left center;
				background-image: url('img/slider-next-dark-grey.svg');

				@include spa() {
					background-image: url('img/slider-next-blue.svg');
				}
				
				@include weddings-events() {
					background-image: url('img/slider-next-heather.svg');
				}
				
				@include country-park() {
					background-image: url('img/slider-next-green.svg');
				}
				
				@include breakpoint(medium) {
					left: 96px;
					right: initial;
				}
			}
		}
	}
	
	&__content {
		position: relative;
		z-index: 5;
		text-align: left;
		color: get-color('dark-grey');
		padding: 65px 20px 0;
		
		@include breakpoint(medium) {
			padding: 0;
		}
		
		h2 {
			@include large-h2();
			margin-bottom: 20px;
			
			a {
				color: inherit;
			}
		}
		
		h3 {
			@include price();
		}
		
		p {
			@include body-copy();
		}
		
		.multiple-buttons {
			text-align: left;
			padding-top: 12px;
		}
	}
	
	&__header {
		&.animate-line::after {
			background-image: url('img/line-dark-grey.svg');
		}
	}
	
	&__links {
		margin: 0 0 25px 0;
		list-style-type: none;
		 
		a {
			position: relative;
			display: inline-block;
			padding-right: 66px;
			color: inherit;
			
			&::after {
				content: '';
				position: absolute;
				width: 50px;
				height: 8px;
				background: no-repeat center;
				background-size: 50px 8px;
				background-image: url('img/slider-next-dark-grey.svg');
				cursor: pointer;
				text-indent: -12345px;
				top: 10px;
				right: 0;
				transition: transform 0.3s ease-in-out;
				transform-origin: left center;
			}
			
			&:hover::after,
			&:active::after {
				transform: scaleX(1.2);
			}
		}
	}
	
	
	&__item {
		position: relative;
		
		// Weird styles allow the sider arrows to be clicked on each item
		/* &:nth-child(1) {
			z-index: 10;
		}
		
		&:nth-child(2) {
			z-index: 9;
		}
		
		&:nth-child(3) {
			z-index: 8;
		}
		
		&:nth-child(4) {
			z-index: 7;
		}
		
		&:nth-child(5) {
			z-index: 6;
		}
		
		&:nth-child(6) {
			z-index: 5;
		}
		
		&:nth-child(7) {
			z-index: 4;
		}
		
		&:nth-child(8) {
			z-index: 3;
		}
		
		&:nth-child(9) {
			z-index: 2;
		}
		
		&:nth-child(10) {
			z-index: 1;
		} */
		
		margin-bottom: 40px;
		
		@include breakpoint(medium) {
			margin-bottom: 95px;
		}

		&:last-of-type {
			margin-bottom: 0px;
		}

		/* &:not(:first-child) {
			@include breakpoint(medium) {
				transform: translateY(-60px);
			}
		} */
		
		&:nth-child(even) {
			.landing__slider {
				@include breakpoint(large) {
					margin-left: 8.3333%;
				}
			}
			
			.landing__content {
				@include breakpoint(medium) {
					padding-left: 60px;
				}
			}
		}
		
		&:nth-child(odd) {
			.landing__slider {
				@include breakpoint(medium) {
					order: 2;

					.slick-prev {
						right: 96px;
						left: auto;
					}
	
					.slick-next {
						right: 0;
						left: auto;
					}
				}
			}
			
			.landing__content {
				@include breakpoint(medium) {
					order: 1;
					padding-left: 30px;
					padding-right: 30px;
				}
				
				@include breakpoint(large) {
					margin-left: 8.3333%;
				}
			}
		}
	}
	
	
	@include country-park() {
		.landing {
			&__header {
				&.animate-line::after {
					background-image: url('img/wave-green.svg');
				}
			}
	
			&__content {
				h2 {
					color: get-color('green');
				}
	
				.button {
					color: get-color('mid-green');
					border-color: get-color('mid-green');
	
					&:hover {
						color: #fff;
						background-color: get-color('mid-green');
					}
	
					&--primary {
						color: #fff;
						background-color: get-color('mid-green');
						border-color: get-color('mid-green');
	
						&:hover {
							color: get-color('mid-green');
							background-color: #fff;
						}
					}
				}
			}
		}
	}
	
	
	
	@include weddings-events() {
		.landing {
			&__header {
				&.animate-line::after {
					background-image: url('img/wave-dark-heather.svg');
				}
			}
	
			&__content {
				h2 {
					color: get-color('heather');
				}
	
				.button {
					color: get-color('dark-heather');
					border-color: get-color('dark-heather');
	
					&:hover {
						color: #fff;
						background-color: get-color('dark-heather');
					}
	
					&--primary {
						color: #fff;
						background-color: get-color('dark-heather');
						border-color: get-color('dark-heather');
	
						&:hover {
							color: get-color('dark-heather');
							background-color: #fff;
						}
					}
				}
			}
		}
	}
	
	
	@include spa() {
		.landing {
			&__header {
				&.animate-line::after {
					background-image: url('img/wave-dark-blue.svg');
				}
			}
	
			&__content {
				h2 {
					color: get-color('blue');
				}
	
				.button {
					color: get-color('dark-blue');
					border-color: get-color('dark-blue');
	
					&:hover {
						color: #fff;
						background-color: get-color('dark-blue');
					}
	
					&--primary {
						color: #fff;
						background-color: get-color('dark-blue');
						border-color: get-color('dark-blue');
	
						&:hover {
							color: get-color('dark-blue');
							background-color: #fff;
						}
					}
				}
			}
		}
	}
}





