.detail {
	position: relative;
	padding-top: 56px;
	margin-bottom: 70px;
	
	~ .intro {
		padding-bottom: 100px;
	}
	
	&__header {
		text-align: center;
		
		h2 {
			font-family: $primary-font;
			font-size: 44px;
			line-height: 40px;
			margin-bottom: 20px;
			
			@include breakpoint(medium) {
				font-size: 50px;
			}
		}
		
		&.animate-line::after {
			background-image: url('img/line-grey.svg');
			left: 50%;
			transform: translateX(-50%);
		}
	}
	
	&__features-list {
		ul {
			margin: 0;
			list-style-type: none;
			line-height: 1;
		}
		
		li {
			position: relative;
			margin-bottom: 30px;
			padding-left: 48px;
			@include bullet-list();
			line-height: 1;
			
			&::before {
				content: '';
				position: absolute;
				top: 2px;
				left: 21px;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				background-color: #C1C3C3;
			}
		}
	}
	
	&__rate {
		text-align: center;
		margin-bottom: 60px;
		padding-top: 20px;
		@include price();
		
		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
		}
		
		em {
			font-size: 25px;
			font-style: normal;
		}
	}
	
	&__cta {
		padding: 0 20px;
	}
	
	@include weddings-events() {
		.detail {
			&__header {
				&::after {
					background-image: url('img/wave-dark-heather.svg');
				}
				
				h2 {
					color: get-color('dark-heather');
				}
			}
		}
		
		.button {
			color: get-color('dark-heather');
			border-color: get-color('dark-heather');
		
			&:hover {
				color: #fff;
				background-color: get-color('dark-heather');
			}
		
			&--primary {
				color: #fff;
				background-color: get-color('dark-heather');
				border-color: get-color('dark-heather');
		
				&:hover {
					color: get-color('dark-heather');
					background-color: #fff;
				}
			}
		}
	}
}