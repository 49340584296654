.pagination {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 60px;
	padding-bottom: 60px;
	text-align: center;
	z-index: 1;
	
	@include breakpoint(large) {
		padding-bottom: 120px;
	}

	@include breakpoint(small only) {
		padding-bottom: 40px;
	}
	
	li {
		@include smallest();
		font-size: 11px;
		font-weight: 700;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: get-color('dark-grey');
		
		@include breakpoint(small only) {
			font-size: 10px;
		}
	}
	
	a {
		color: inherit;
		
		&:hover {
			background-color: transparent;
		}
	}
	
	&-previous,
	&-next {
		
		&.disabled {
			&::before, 
			&::after {
				content: none;
			}
		}

		a {
			position: relative;
			display: inline-block;
			height: 21px;
			line-height: 21px;
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 700;

			@include breakpoint(small only) {
				font-size: 10px;
			}
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 4px;
				width: 50px;
				height: 20px;
				background: no-repeat;
				background-size: 50px 8px;
				transition: transform 0.3s ease-in-out;
			}
			
			&:hover,
			&:active {
				&::after,
				&::before {
					transform: scaleX(1.2);
				}
			}
		}	
	}
	
	&-previous {
		float: left;
		color: get-color('dark-grey');
		
		&.disabled::before {
			content: '';
			width: 50px;
			height: 8px;
			background: url('img/bullet-list-arrow-left.svg') no-repeat;
			background-position: right center;
			background-size: 42px 8px;
		}
		
		a {
			padding-left: 60px;
			margin-left: 40px;
			
			&::after {
				left: 0;
				background-image: url('img/bullet-list-arrow-left.svg');
				background-position: right center;
				transform-origin: right center;
			}
		}
	}
	
	&-next {
		float: right;
		
		a {
			padding-right: 60px;
			margin-right: 40px;
			
			&::after {
				right: 0;
				background-image: url('img/bullet-list-arrow.svg');
				background-position: left center;
				transform-origin: left center;
			}
		}
	}
}