.other-rooms {
	position: relative;
	background: linear-gradient(to bottom, get-color('dark-grey') 0%, #B2B2B1 100%);
	padding: 60px 20px;
	text-align: center;
	color: #fff;
	padding-bottom: 100px;
	
	&::after {
		content: '';
		position: absolute;
		z-index: 5;;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		background: url('img/other-rooms-bg-small.svg') no-repeat right -280px bottom 0;
		
		@include breakpoint(medium) {
			background: url('img/other-rooms-bg-medium.svg') no-repeat left 107px bottom 0;
		}
	}
	
	&__header {
		&::after {
			left: 50%;
			transform: translateX(-50%);
		}
		
		h2 {
			@include large-h2();
		}
	}
	
	&__wrapper {
		position: relative;
		z-index: 10;
		width: 100%;
		max-width: 1260px;
		margin: 0 auto;

		@include breakpoint(medium down) {
			max-width: 430px;
		}
	}
	
	&__room {
		transition: all 0.3s ease-in-out;
	}
	
	&__image {
		margin-bottom: 25px;
		
		img {
			transition: all 0.3s ease-in-out;
		}
	}
	
	&__content {
		transition: all 0.3s ease-in-out;
		
		h3 {
			@include large-h2();
			margin-bottom: 50px;
			
			a {
				color: inherit;
			}
		}
	}
	
	&__rate {
		@include price();
		margin: 20px 0 50px;
		
		em {
			font-size: 25px;
			font-style: normal;
		}
	}
	
	.multiple-buttons {
		margin-bottom: 40px;
	}
	
	&__carousel.one-room {
		max-width: 400px;
		margin: auto;
	}
	
	&__carousel.two-rooms {
		max-width: 750px;
		margin: auto;

		.slick-slide {
			padding: 0 20px;
		}

		.slick-arrow {
			position: absolute;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			bottom: 20%;
			transition: transform 0.3s ease-in-out;

			@include breakpoint(medium down) {
				bottom: -50px;
			}
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: -5%;
				transform-origin: right center;
				background-image: url('img/slider-prev-white.svg');
			}
			
			&.slick-next {
				right: -5%;
				transform-origin: left center;
				background-image: url('img/slider-next-white.svg');
			}
		}
	}
	
	&__carousel.center-mode {
		.other-rooms {
			&__room {
				@include breakpoint(large) {
					padding-left: 50px;
					padding-right: 50px;
					
					img {
						margin-top: 32%;
						transform: translateY(-45px);
					}
				}
			}
			
			&__content {
				@include breakpoint(large) {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		
		.slick-current .other-rooms {
			&__room {
				@include breakpoint(large) {
					//padding-top: 0;
					padding-left: 0;
					padding-right: 0;
					
					img {
						margin-top: 0;
						transform: translateY(0);
					}
				}
			}
			
			&__content {
				@include breakpoint(large) {
					opacity: 1;
					pointer-events: all;
				}
			}
			
			&__rate {
				@include breakpoint(large) {
					display: block;
				}
			}
		}

		.slick-arrow {
			position: absolute;
			z-index: 20;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			bottom: 300px;
			transition: transform 0.3s ease-in-out;

			@include breakpoint(medium down) {
				bottom: -50px;
			}
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: 25%;
				transform-origin: right center;
				background-image: url('img/slider-prev-white.svg');
			}
			
			&.slick-next {
				right: 25%;
				transform-origin: left center;
				background-image: url('img/slider-next-white.svg');
			}
		}
	}
	
	@include weddings-events() {
		@include heather-gradient();
		
		.button {
			&--primary-white-outline {
				color: #fff;
				background-color: get-color('dark-heather');
		
				&:hover {
					color: get-color('dark-heather');
					background-color: #fff;
				}
			}
		}
	}
}