.social {
	position: relative;
	padding-top: 40px;
	padding-bottom: 15px;
	background-color: get-color('pale-grey');
	
	@include breakpoint(small only) {
		padding-top: 85px;
	}
	
	&__header {
		text-align: center;
		margin-bottom: 30px;
		
		h2 {
			@include h1();
			font-family: $tertiary-font;
			margin-bottom: 0;
			
		}
		
		h3 {
			@include burger-menu-sub-nav();
			margin-bottom: 15px;
			
			a {
				color: inherit;
			}
		}
	}
	
	&__feed {
		@include breakpoint(medium) {
			padding: 0 25px;
		}
		
		.crt-grid-post {
			@include breakpoint(medium) {
				padding: 0 12px;
			}
		}
	}
}