.intro {
	background-color: get-color('intro-grey');
	padding-top: 60px;
	padding-bottom: 90px;
	text-align: center;
	
	@include breakpoint(medium) {
		padding-bottom: 0px;
	}
	
	&__content {
		color: get-color('dark-grey');
		
		@include breakpoint(medium) {
			padding: 0 50px 140px;
			
			@at-root body.template-gallery #{&} {
				padding-bottom: 60px;
			}
			
			@at-root body.template-contact #{&} {
				padding-bottom: 60px;
			}
		}
		
		h1 {
			@include h1;
			margin-bottom: 0;
			margin-bottom: 30px;
			
			em {
				font-style: normal;
				font-family: $tertiary-font;
			}
			
		}
		
		h2 {
			@include h2;
			scroll-margin-top: 20px;
			margin-bottom: 40px;
		}
		
		p {
			@include body-copy();
		}
		
		.multiple-buttons {
			padding-top: 38px;
		}
	}
	
	&__image {
		@include flex-align-bottom();
		
		@include breakpoint(small only) {
			padding-top: 50px;
			
			
			img {
				margin-bottom: -90px;
			}
		}
	}
	
	
	@include country-park() {
		h1 {
			color: get-color('mid-green');
		}
	
		.button {
			color: get-color('mid-green');
			border-color: get-color('mid-green');

			&:hover {
				color: #fff;
				background-color: get-color('mid-green');
			}

			&--primary {
				color: #fff;
				background-color: get-color('mid-green');
				border-color: get-color('mid-green');

				&:hover {
					color: get-color('mid-green');
					background-color: #fff;
				}
			}
		}
	}

	@include template-home() {
		
		h1 {
			color: get-color('mid-green');
		}
		
		h2 {
			color: get-color('mid-green');
		}
		
		p {
			color: get-color('mid-green');
		}

		.button {
			color: get-color('mid-green');
			border-color: get-color('mid-green');

			&:hover {
				color: #fff;
				background-color: get-color('mid-green');
			}

			&--primary {
				color: #fff;
				background-color: get-color('mid-green');
				border-color: get-color('mid-green');

				&:hover {
					color: get-color('mid-green');
					background-color: #fff;
				}
			}
		}
	}
	
	
	@include weddings-events() {
		h1 {
			color: get-color('dark-heather');
		}
	
		.button {
			color: get-color('dark-heather');
			border-color: get-color('dark-heather');

			&:hover {
				color: #fff;
				background-color: get-color('dark-heather');
			}

			&--primary {
				color: #fff;
				background-color: get-color('dark-heather');
				border-color: get-color('dark-heather');

				&:hover {
					color: get-color('dark-heather');
					background-color: #fff;
				}
			}
		}
	}
	
	
	@include spa() {
		h1 {
			color: get-color('dark-blue');
		}
	
		.button {
			color: get-color('dark-blue');
			border-color: get-color('dark-blue');

			&:hover {
				color: #fff;
				background-color: get-color('dark-blue');
			}

			&--primary {
				color: #fff;
				background-color: get-color('dark-blue');
				border-color: get-color('dark-blue');

				&:hover {
					color: get-color('dark-blue');
					background-color: #fff;
				}
			}
		}
	}
}

