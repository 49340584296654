.accordion {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    &-wrap {
        @include grey-gradient;
        position: relative;
        padding: 60px 0 210px;
        
        @include spa(){
            @include blue-gradient();   
        }
        
        @include weddings-events() {
            @include heather-gradient();
        }
        
        @include country-park() {
            @include green-gradient();
        }

        @include breakpoint(small only) {
            padding: 60px 20px 140px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url('img/slider-leaves.png');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
            transform: scaleX(-1);
            width: 100%;
            height: 355px;
            z-index: 0;
        }
    }
    
    &__section {
        h2 {
            font-family: $primary-font;
            font-size: 30px;
            line-height: 33px;
            color: #fff;
            margin-bottom: 28px;
        }
    }

    .accordionButton {
        font-family: $primary-font;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.075;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        z-index: 1;
        background-color: rgba(get-color('dark-grey'),0.65);
        padding: 10px 30px;
        margin-bottom: 2px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            background: url('img/accordion-open.svg') no-repeat center center;
            background-size: 12px 15px;
            width: 12px;
            height: 15px;
        }

        &.on {

            &::after {
                
                background: url('img/accordion-close.svg') no-repeat center center;
                background-size: 12px 15px;
            }
        }

        h3 {
            margin-bottom: 0;
            font-family: $primary-font;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.075em;
        }
    }

    .accordionContent {
        z-index: 1;
    }

    &__content {
        padding: 20px;

        @include breakpoint(medium) {
            padding: 30px 50px;
            max-width: 75%;
        }

        p,
        li {
            @include body-copy;
            color: #fff;
            
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        
        li {
            margin-bottom: 0;
        }
    }
    
    
    
}