.button {
	color: get-color('dark-grey');
	min-width: 157px;
	padding: 12px 20px;
	text-align: center;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	background-color: transparent;
	border: 1px solid get-color('dark-grey');
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	font-weight: bold;
	font-family: $primary-font;
	
	&:hover,
	&:active {
		color: get-color('dark-grey');
		background-color: get-color('pale-grey');
	}
	
	&--white-outline {
		border-color: #fff;
		color: #fff;
		
		&:hover,
		&:active {
			border-color: get-color('dark-grey');
			color: get-color('dark-grey');
			background-color: #fff;
		}
	}
	
	&--primary {
		color: #fff;
		background-color: get-color('dark-grey');
		
		&:hover,
		&:active {
			color: get-color('dark-grey');
			background-color: #fff;
		}
	}
	
	&--primary-white-outline {
		color: #fff;
		border-color: #fff;
		background-color: get-color('dark-grey');
		
		&:hover,
		&:active {
			color: get-color('dark-grey');
			background-color: #fff;
			border-color: get-color('dark-grey');
		}
	}
	
	&--green {
		
	}
	
	&--green-primary {
		border-color: #fff;
		color: #fff;
		background-color: get-color('mid-green');
		
		&:hover,
		&:active {
			border-color: get-color('mid-green');
			color: get-color('mid-green');
			background-color: #fff;
		}
	}

	&--dark-blue-primary {
		border-color: get-color('dark-blue');
		color: #fff;
		background-color: get-color('dark-blue');
		
		&:hover,
		&:active {
			border-color: get-color('blue');
			color: #fff;
			background-color: get-color('blue');
		}
	}

	&--dark-blue-outline {
		border-color: get-color('dark-blue');
		color: get-color('dark-blue');
		
		&:hover,
		&:active {
			border-color: get-color('blue');
			color: #fff;
			background-color: get-color('blue');
		}
	}
}

.multiple-buttons {
	text-align: center;
	width: calc(100% + 24px);
	margin-left: -12px;
	margin-right: -12px;
	
	.button {
		margin: 0 12px 12px;
	}
}

