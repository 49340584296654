.dining-detail {
    background: linear-gradient(to bottom, get-color('dark-green') 0%, get-color('green') 100%);
    padding: 80px 0px 210px;
    position: relative;
    
    @include breakpoint(medium only) {
        padding-bottom: 120px;
    }

    @include breakpoint(small only) {
        padding: 80px 0 140px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('img/slider-leaves.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        width: 100%;
        height: 355px;
        transform: scaleX(-1);
        z-index: 0;
    }

    .tree {
        background-color: rgba(get-color('pale-green'),0.75);
    }

    &__inner {

    }

    h2 {
        @include large-h2;
        color: #fff;
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        margin: 0 0 30px 0;

        li {
            @include bullet-list;
            color: #fff;
            line-height: 1.4;
            margin-bottom: 20px;
            
            a {
                @include bullet-list;
                color: #fff;
                padding-right: 60px;
                line-height: 1.5;
                position: relative;
                display: block;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 60px;
                    height: 11px;
                    transform: translateY(-50%);
                    background: url('img/bullet-list-arrow-white.svg') no-repeat center right;
                }

                &:hover {

                    &::after {
                       transform: translateY(-50%) scaleX(1.3);
                    }
                }
            }
        }
    }

    &__menus {

    }

    &__image {

        @include breakpoint(medium only) {
            padding: 0 20px;
        }
        
        @include breakpoint(small only) {
            margin-bottom: 30px;
        }
    }

    &__opening {
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}