.blog-detail {
    padding: 90px 0 80px;

    &__inner {

    }

    &__text {
        @include breakpoint(medium) {
            padding-right: 40px;
        }
        
        &-sub-title {

            h2 {
                @include h1;
                font-size: 44px;
                line-height: 50px;
                color: get-color('dark-grey');
                
                @include breakpoint(medium) {
                    font-size: 50px;
                }
            }
        }

        &-content {

            p {
                @include body-copy;
                color: get-color('dark-grey');
                letter-spacing: 0.025em;
            }

            ol, ul {

                li {
                    @include body-copy;
                    color: get-color('dark-grey');
                    letter-spacing: 0.025em;
                }
            }

            a {
                @include body-copy;
                color: get-color('dark-grey');
                letter-spacing: 0.025em;
            }
        }
    }

    &__sidebar {

        &-image {
            margin-bottom: 60px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &-related {

            h4 {
                @include price;
                color: get-color('dark-grey');
                font-weight: 500;
                text-transform: uppercase;
            }

            ul {
                list-style: none;
                margin: 0;
                max-width: 280px;

                li {
                    @include bullet-list;
                    color: get-color('dark-grey');
                    line-height: 1;
                    
                    a {
                        @include bullet-list;
                        color: get-color('dark-grey');
                        padding-right: 70px;
                        position: relative;
                        display: block;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            width: 60px;
                            height: 11px;
                            transform: translateY(-50%);
                            background: url('img/bullet-list-arrow.svg') no-repeat center right;
                        }

                        &:hover {

                            &::after {
                                background: url('img/bullet-list-arrow-hover.svg') no-repeat center right;
                            }
                        }
                    }
                }
            }
        }

    }
}