.form {
	.g-recaptcha {
		margin-top: 30px;
	}
	
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	textarea {
		border: 0;
		border-radius: 0;
		background-color: #fff;
		width: 100%;
		height: 49px;
		padding-left: 15px;
		font-family: $primary-font;
		font-weight: bold;
		font-size: 10px;
		color: get-color('black');
		
		@include placeholder {
			opacity: 1;
			color: inherit;
			text-transform: uppercase;
			letter-spacing: 0.1em;
		}
	}
	
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"] {
		
	}
	
	textarea {
		padding: 20px;
		min-height: 120px;
	}
	
	label.error {
		color: #a70000;
		display: block;
		margin-bottom: 0px;
		transform: translateY(-15px);
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	
	.consent ~ .field-validation-error {
		margin-top: 10px;
		display: block;
	}
	
	&__checkbox {
		position: relative;
		text-align: left;
		
		input[type="checkbox"] {
			position: absolute; 
			opacity: 0;
			top: 0px;
			z-index: 1;
			
			~ label {
				position: relative;
				padding-left: 28px;
				padding-top: 1px;
				cursor: pointer; 
				line-height: 1;
				display: block;
				@include smallest();
				
				&::before,
				&::after {
					content: '';
					position: absolute; 
				}
				
				&::before {
					top: 0;
					left: 0;
					width: 15px;
					height: 15px;
					border: 1px solid;
					background-color: #fff;
				}
				
				&::after {
					content: '✓';
					color: #000;
					font-size: 12px;
					padding: 3px 0 0 1px;
					top: -1px;
					left: 2px;
					width: 14px;
					height: 14px;
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}
				
				a {
					color: inherit;
					text-decoration: underline;
					transition: opacity 0.3s ease-in-out;
					
					&:hover,
					&:active {
						opacity: 0.7;
					}
				}
			}
			
			&:checked ~ label {
				&:after {
					opacity: 1;  
				}
			}
		}
	}
	
	&__submit {
		@include breakpoint(large) {
			text-align: right;
		}
	}
}