.booking {
 
    &__main {

    }

    &__wrapper {

    }

    &__tabs {

        .tabs {
            background-color: transparent;
            border: none;
         
            .tabs-title {
                border: 1px solid #fff;
                background-color: transparent;
                padding: 0;
                text-align: center;

                @include breakpoint(small only) {
                    margin-bottom: 20px;
                }
    
                a {
                    @include smallest;
                    font-weight: 700;
                    color: #fff;
                    padding: 10px;

                    &:hover {
                        background-color: #fff;
                        color: get-color('mid-green');
                    }
                }

                &.is-active {

                    a {
                        background-color: #fff;
                        color: get-color('mid-green');
                    }
                }
            }
        }

        &-content {

            &-room {

                form {

                    & > div {

                        & > div {
                            background-color: #fff;
                            padding: 30px 10px;
                            min-height: 200px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            &:last-of-type {
                                background-color: transparent;
                                min-height: auto;

                                @include breakpoint(small only) {
                                    padding: 10px 0;
                                }
                            }

                            @include breakpoint(small only) {
                                margin-bottom: 20px;
                            }

                        }
                    }

                    label {
                        font-size: 13px;
                        font-family: $primary-font;
                        color: get-color('dark-grey');
                        font-weight: 700;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                    }
                }

                &-submit {
                    
                    button {
                        color: #fff;
                        border-color: #fff;
                        background-color: get-color('mid-green');

                        &:hover {
                            color: get-color('mid-green');
                            border-color: get-color('mid-green');
                            background-color: #fff;
                        }
                    }
                }

                &-why-book {
                    padding: 10px 20px;
                    background-color: get-color('mid-green');
                    color: #fff;
                    font-family: $primary-font;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.075em;
                    text-transform: uppercase;
                    text-align: left;

                    .accordionButton {
                        position: relative;
                        
                        img {
                            float: right;
                            transition: all 0.3s ease-in-out;
                            padding-top: 5px;
                        }
                        
                        &.on {
                            
                            img {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .accordionContent {
                        margin-top: 20px;

                        p {
                            @include body-copy;
                            text-transform: initial;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &__rooms {

        &-field {

            input {
                width: 100%;
                border: none;
                text-align: center;
                font-family: $primary-font;
                color: get-color('green');
                font-size: 54px;
                background: url('img/small-arrow-green.svg') no-repeat 95% 60%;
            }

            .arrival-date, .depart-date {
                position: relative;
            }

            .adults-select {
                padding-top: 5px;
            }

            .selectric {
                border: none;
                text-align: center;
                height: 100%;

                &::after {
                    top: 50%;
                    right: 25px;
                    background-image: url('img/small-arrow-green.svg');
                    width: 15px;
                    height: 15px;
                }

                &-wrapper {
                    height: 100%;
                }

                span {
                    text-align: center;
                    margin: 0 auto;
                    width: 100%;
                    height: 100%;
                    font-family: $primary-font;
                    color: get-color('green');
                    font-size: 54px;
                    font-weight: 400;
                    line-height: 1.1;
                }

                b.button {
                    display: none;
                }
            }
        }
    }
}