.reveal {
	z-index: 50000;
	background-color: rgba(#fff, 0.7);
	backdrop-filter: blur(2px);
	
	&-overlay {
		padding-top: 30px;
	}
	
	&.full {
		top: 0 !important;
		min-height: 100vh;
	}
}