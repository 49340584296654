@mixin slider-caption {
	font-family: $primary-font;
	font-size: 34px;
	line-height: 34px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 300;
	
	@include breakpoint(medium) {
		font-size: 45px;
		line-height: 40px;
	}
}


@mixin h1 {
	font-family: $primary-font;
	font-size: 40px;
	line-height: 40px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 300;
	
	@include breakpoint(medium) {
		font-size: 45px;
		line-height: 40px;
	}
}


@mixin large-h2 {
	font-family: $primary-font;
	font-size: 40px;
	line-height: 40px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 300;
	
	@include breakpoint(medium) {
		font-size: 45px;
		line-height: 40px;
	}
}


@mixin h2 {
	font-family: $tertiary-font;
	font-size: 25px;
	line-height: 30px;
}

@mixin price {
	font-family: $primary-font;
	font-size: 14px;
	line-height: 25px;
	font-weight: bold;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	
	em {
		font-style: normal;
		font-size: 25px;
	}
}


@mixin burger-menu-sub-nav {
	font-family: $primary-font;
	font-size: 25px;
	line-height: 27px;
}


@mixin body-copy {
	font-family: $primary-font;
	font-size: 15px;
	line-height: 23px;
	letter-spacing: 0.025em;
	margin-bottom: 20px;
}


@mixin bullet-list {
	font-family: $primary-font;
	font-size: 14px;
	line-height: 25px;
	font-weight: bold;
	letter-spacing: 0.075em;
	text-transform: uppercase;
}


@mixin menu-list {
	font-family: $primary-font;
	font-size: 14px;
	line-height: 25px;
	font-weight: bold;
	letter-spacing: 0.075em;
	text-transform: uppercase;
}


@mixin terms-conditions {
	font-family: $primary-font;
	font-size: 12px;
	line-height: 20px;
}


@mixin smallest {
	font-family: $primary-font;
	font-size: 11px;
	line-height: 21px;
	text-transform: uppercase;
}