.home {
	&__events {
		position: relative;
		padding: 30px 0;
		text-align: center;
		background: get-color('green');
		color: #fff;
		
		@include breakpoint(medium) {
			padding-left: 90px;
			padding-right: 90px;
			padding-bottom: 120px;
		}
		
		.tree {
			background: rgba(get-color('green'), 0.61);
		}
		
		h2 {
			font-family: $tertiary-font;
			font-size: 65px;
			margin-bottom: 10px;
		}
		
		h3 {
			@include h2;
			font-size: 30px;
			font-weight: 300;
			margin-bottom: 30px;
			
			&::before {
				content: '- ';
			}
			
			&::after {
				content: ' -';
			}
		}
		
		&-item {
			padding: 0 20px;
		}
		
		&-image {
			overflow: hidden;
			margin-bottom: 25px;
			
			img {
				display: block;

				&.round-image {
					border-radius: 50%;
				}
			}
			
			a {
				display: block;
				
				&.is-video {
					position: relative;
					border-radius: 100%;
					overflow: hidden;
					
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url('img/video-play-white.svg') no-repeat center;
						
						@include breakpoint(small only) {
							background-size: 67px 67px;
						}
					}
				}
			}
		}
		
		&-content {
			h4 {
				@include h2;
				margin-bottom: 10px;
				
				em {
					font-style: normal;
					font-family: $tertiary-font;
				}
			}
			
			p {
				@include body-copy();
				margin-bottom: 10px;
			}
		}
		
		.slick-arrow {
			position: absolute;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			top: 26%;
			transition: transform 0.3s ease-in-out;
			z-index: 1;
			
			@include breakpoint(medium) {
				margin-top: -20px;
			}

			@include breakpoint(small only) {
				top: auto !important;
				bottom: -45px;
			}
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: calc(50% - 80px);
				transform-origin: right center;
				background-image: url('img/slider-prev-white.svg');
				
				@include breakpoint(medium) {
					left: -50px;
				}
			}
			
			&.slick-next {
				right: calc(50% - 80px);
				transform-origin: left center;
				background-image: url('img/slider-next-white.svg');
				
				@include breakpoint(medium) {
					right: -50px;
				}
			}
		}
	}
	
	
	&__row {
		position: relative;
		padding: 60px 0;
		color: #fff;
		
		@include breakpoint(medium) {
			padding-bottom: 135px;
		}
		
		@include breakpoint(medium) {
			padding-bottom: 15px;
		}
		
		> .grid-container {
			@include breakpoint(large) {
				transform: translateY(-120px);
				
				.home__row-content-inner  {
					padding-bottom: 50px;
				}
			}
		}
		.home__row-content {
			@include flex-align-bottom();
		}
		
		&-slider {
			a {
				display: block;
				overflow: hidden;
			}
		}
		
		&-content {
			&-inner {
				padding: 46px 0 20px;
				
				@include breakpoint(medium) {
					padding: 20px;
				}
				
				@include breakpoint(large) {
					padding: 40px 70px;
				}
			}
			
			h2 {
				@include large-h2();
				margin-bottom: 20px;
				
				a {
					color: inherit;
				}
				
				em {
					font-style: normal;
					color: rgba( #fff, 0.5 );
				}
			}
			
			p {
				@include body-copy();
			}
		}
		
		
		&-header {
			h2 {
				margin: 0;
			}
		}
		
		&-links {
			margin: 0 0 25px 0;
			list-style-type: none;
			
			a {
				position: relative;
				display: inline-block;
				padding-right: 66px;
				color: inherit;
				
				&::after {
					content: '';
					position: absolute;
					width: 50px;
					height: 8px;
					background: no-repeat center;
					background-size: 50px 8px;
					background-image: url('img/slider-next-white.svg');
					cursor: pointer;
					text-indent: -12345px;
					top: 10px;
					right: 0;
					transition: transform 0.3s ease-in-out;
					transform-origin: left center;
				}
				
				&:hover::after,
				&:active::after {
					transform: scaleX(1.2);
				}
			}
		}
		
		&-cta {
			padding-top: 15px;

			a {
				margin: 0 15px 1rem;
			}
		}
		
		
		
		&--country-park {
			@include green-gradient();
			
			&::after {
				content: '';
				position: absolute;
				bottom: 130px;
				right: 0;
				width: 330px;
				height: 355px;
				background: url('img/home-country-park-bg.svg') no-repeat right bottom;
				background-size: contain;
				
				@include breakpoint(small only) {
					bottom: 15px;
					width: 206px;
					height: 285px;
				}
			}
		}
		
		&--weddings-events {
			@include heather-gradient();
		}
		
		&--spa {
			@include blue-gradient();
		}
		
		&--image-left {
			.home__row-slider {
				@include breakpoint(large) {
					padding-left: 40px;
				}
			}
		}
		
		&--image-right {
			.home__row-slider {
				@include breakpoint(medium) {
					order: 2;
				}
				
				@include breakpoint(large) {
					padding-right: 40px;
				}
			}
			
			.home__row-content {
				@include breakpoint(medium) {
					order: 1;
					padding-top: 60px;
				}
				
				&-inner {
					@include breakpoint(large) {
						padding-left: 143px;
						padding-right: 0;
					}
				}
			}
		}
		
		&--large-image {

			
		}
		
		
		.slick-arrow {
			position: absolute;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			bottom: -30px;
			transition: transform 0.3s ease-in-out;
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: calc(50% - 75px);
				transform-origin: right center;
				background-image: url('img/slider-prev-white.svg');
			}
			
			&.slick-next {
				right: calc(50% - 75px);
				transform-origin: left center;
				background-image: url('img/slider-next-white.svg');
			}
		}
	}
}