.offers {
    padding: 85px 0 150px;
    position: relative;

    @include breakpoint(small only) {
        padding: 60px 0 ;
    }

    &__grid {

    }

    &__item {
        margin-bottom: 50px;

        &:nth-child(even) {

            .offers__item-image {
                order: 2;

                @include breakpoint(small only) {
                    order: 1;
                }
            }

            .offers__item-content {
                order: 1;
                padding: 5% 5% 5% 0;
                
                @include breakpoint(large) {
                    padding: 10% 5% 10% 0;
                }

                @include breakpoint(small only) {
                    order: 2;
                }
            }
        }

        &-image {

            @include breakpoint(small only) {
                margin-bottom: 40px;
            }

            img {
                width: 100%;
                height: 100% !important;
                object-fit: cover;
            }
        }

        &-content {
            padding: 5% 0 5% 5%;

            @include breakpoint(large) {
                padding: 10% 0 10% 5%;
            }

            @include breakpoint(small only) {
                padding: 0 !important;
            }

            &-top {
                &.animate-line::after {
                    background-image: url('img/line-dark-grey.svg');
                }
                
                h2 {
                    @include large-h2;
                    margin-bottom: 20px;

                    a {
                        color: inherit;
                    }
                }

                h4 {
                    color: get-color('dark-grey');
                    font-size: 14px;
                    line-height: 25px;
                    font-weight: 500;
                    letter-spacing: 0.075em;
                    font-family: $primary-font;
                    text-transform: uppercase;
                    margin-bottom: 20px;

                    span {
                        font-size: 24px;
                    }
                }
            }

            &-bottom {

                @include breakpoint(small only) {
                    margin-bottom: 10px;
                }

                p {
                    @include body-copy;
                    color: get-color('dark-grey');
                    padding-right: 15%;
                }
            }

            &-cta {

                .multiple-buttons {

                    @include breakpoint(small only) {
                        text-align: left;
                    }
                    
                    .button:first-child {
                        margin-bottom: 25px;
                    }
                }
                
                
            }
        }
    }
    
    @include spa() {
        .offers {
            &__item-content-top {
                &::after {
                    background-image: url('img/wave-dark-blue.svg');
                }
                
                h2 {
                    color: get-color('blue');
                }
            }
    
            &__item-content-cta {
                .button {
                    color: get-color('dark-blue');
                    border-color: get-color('dark-blue');
    
                    &:hover {
                        color: #fff;
                        background-color: get-color('dark-blue');
                    }
    
                    &--primary {
                        color: #fff;
                        background-color: get-color('dark-blue');
                        border-color: get-color('dark-blue');
    
                        &:hover {
                            color: get-color('dark-blue');
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
    
    
    @include country-park() {
        .offers {
            &__item-content-top {
                &::after {
                    background-image: url('img/wave-green.svg');
                }
                
                h2 {
                    color: get-color('green');
                }
            }
    
            &__item-content-cta {
                .button {
                    color: get-color('mid-green');
                    border-color: get-color('mid-green');
                
                    &:hover {
                        color: #fff;
                        background-color: get-color('mid-green');
                    }
                
                    &--primary {
                        color: #fff;
                        background-color: get-color('mid-green');
                        border-color: get-color('mid-green');
                
                        &:hover {
                            color: get-color('mid-green');
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}