.curtain {
	position: absolute;
	z-index: 500;
	width: 100%;
	height: 100%;
	background-color: #fff;
	transform-origin: left;
	
	&--right {
		transform-origin: right;
	}
}







.transition {
	transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01), opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
	will-change: transform, opacity;
	backface-visibility: hidden;
	
	&--250  { transition-duration:  250ms; }
	&--500  { transition-duration:  500ms; }
	&--750  { transition-duration:  750ms; }
	&--1000 { transition-duration: 1000ms; }
	&--1250 { transition-duration: 1250ms; }
	&--1500 { transition-duration: 1500ms; }
	&--1750 { transition-duration: 1750ms; }
	&--2000 { transition-duration: 2000ms; }
	&--2250 { transition-duration: 2250ms; }
	&--2500 { transition-duration: 2500ms; }
	&--3000 { transition-duration: 3000ms; }
	&--3500 { transition-duration: 3500ms; }
	&--stacking {
		transition-duration: 1000ms;

		&:nth-child(3n+2) {
			transition-duration: 2000ms;
		}
		&:nth-child(3n+3) {
			transition-duration: 3000ms;
		}
	}
}

.transform {
	&--zero-width {
		transform: scale(0,1);
	}
	
	
	&--will-slide-in {
		transform: translateX(-100px);
	}
	&--completed-slide-in {
		transform: translateX(0);
	}
	
	
	&--will-slide-in-right {
		transform: translateX(100px);
	}
	&--completed-slide-in-right {
		transform: translateX(0);
	}
	
	&--will-slide-down {
		transform: translateY(-100px);
	}
	&--completed-slide-down {
		transform: translateX(0);
	}
	
	
	&--will-fade-in {
		opacity: 0;
	}
	&--completed-fade-in {
		opacity: 1;
	}
	
	
	&--will-fade-up {
		opacity: 0;
		transform: translateY(100px);
	}
	&--completed-fade-up {
		opacity: 1;
		transform: translateY(0);
	}
	
	
	&--will-fade-down {
		opacity: 0;
		transform: translateY(-100px);
	}
	&--completed-fade-down {
		opacity: 1;
		transform: translateY(0);
	}
	
	
	&--will-fade-left {
		opacity: 0;
		transform: translateX(100px);
	}
	&--completed-fade-left {
		opacity: 1;
		transform: translateX(0);
	}
	
	
	&--will-fade-right {
		opacity: 0;
		transform: translateX(-100px);
	}
	&--completed-fade-right {
		opacity: 1;
		transform: translateX(0);
	}
	
	
	&--will-zoom-out {
		transform: scale(1.1);
	}
	&--completed-zoom-out {
		transform: scale(1);
	}
}

.animate-line {
	position: relative;
	padding-bottom: 30px;
	margin-bottom: 28px;
	
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 14px;
		background: url('img/line-white.svg') no-repeat left center;
		background-size: 68px 14px;
		animation-delay: 1s;
		transition: width 0.5s ease-in-out;
		
		@include breakpoint(medium) {
			height: 19px;
			background-size: 90px 19px;
		}
	}
	
	&.in-view::after {
		width: 68px;
		
		@include breakpoint(medium) {
			width: 90px;
		}
	}
}