.popup {
	@at-root .reveal-overlay {
		z-index: 60000;
		background: rgba(get-color('dark-grey'), 0.95);
	}
	
	&.reveal {
		max-width: 985px;
		border: 0;
		padding: 0;
		overflow: visible;
		padding: 9px;
		background: #fff;
		border: 1px solid #000;
		display: flex;
		align-items: stretch;
		
		@include breakpoint(small only) {
			padding: 9px;
			max-width: 90%;
			width: 100%;
			margin: 10% auto 0 auto;
			height: auto;
			min-height: auto;
		}
		
		&:focus {
			outline: none;
		}
	}
	
	&__close {
		position: absolute;
		top: -20px;
		right: -20px;
		width: 16px;
		height: 16px;
		background: url('img/close-round-white.svg') no-repeat center center;
		background-size: 15px 15px;
		text-indent: -12345px;
		cursor: pointer;
		
		@include breakpoint(small only) {
			top: -25px;
			right: -5px;
		}
	}
	
	&__text {
		justify-content: center;
		display: flex;
		flex-direction: column;
		padding: 5%;
		background: linear-gradient(to bottom, get-color('dark-green') 0%, get-color('green') 100%);

		@include breakpoint(small only) {
			padding: 60px 20px;
		}
		
		h2 {
			@include large-h2;
			color: #fff;
			margin-bottom: 15px;
		}

		h3 {
			font-family: $primary-font;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.075em;
		}
		
		p {
			@include body-copy;
			color: #fff;
			margin-bottom: 30px;
			max-width: 85%;
			width: 100%;
		}
		
		.multiple-buttons {
			text-align: left;
		}
	}

	&__image {

		img {
			width: 100%;
			height: 100% !important;
			object-fit: cover;
		}
	}
}