.slider {
	position: relative;
	text-align: center;
	
	.slider__inner {
		width: 100vw;
		height: 75vh;
	}
	
	&--full-height {
		.slider__inner {
			width: 100vw;
			height: 100vh;
		}
	}
	
	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		overflow: hidden;
		
		img {
			object-fit: cover;
			width: 100vw;
			height: 100vh;
		}
	}
	
	&__video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		
		iframe,
		video {
			position: absolute;
		}
	}
	
	&__image,
	&__video {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.52) url('img/slider-leaves.png') no-repeat right bottom;
			
			@include breakpoint(medium down) {
				background-size: 208px 247px;
			}
		}
	}
	
	&__caption {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 140px;
		padding-bottom: 73px;
		background: url('img/line-white.svg') no-repeat center bottom;
		
		@include breakpoint(small only) {
			bottom: 140px;
			padding-bottom: 58px;
			background-size: 90px 19px;
		}
		
		h2 {
			color: #fff;
			@include slider-caption();
			margin: 0;
		}
	}
}
