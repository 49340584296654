.accommodation {
	margin-bottom: 113px;
	padding-top: 60px;
	position: relative;
	
	@include breakpoint(medium only) {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	@include breakpoint(medium) {
		padding-top: 110px;
		margin-bottom: 120px;
	}
	
	&__slider {
		.slick-arrow {
			position: absolute;
			z-index: 10;
			width: 50px;
			height: 40px;
			background: no-repeat center;
			background-size: 50px 8px;
			cursor: pointer;
			text-indent: -12345px;
			bottom: -30px;
			transition: transform 0.3s ease-in-out;
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
			
			&.slick-prev {
				left: calc(50% - 75px);
				transform-origin: right center;
				background-image: url('img/slider-prev-dark-grey.svg');
				
				@include breakpoint(medium) {
					left: 0;
				}
			}
			
			&.slick-next {
				right: calc(50% - 75px);
				transform-origin: left center;
				background-image: url('img/slider-next-dark-grey.svg');
				
				@include breakpoint(medium) {
					left: 96px;
					right: initial;
				}
			}
		}
	}
	
	&__header {
		&.animate-line::after {
			background-image: url('img/line-grey.svg');
		}
	}
	
	&__content {
		@include flex-align-bottom();
		padding: 65px 20px 0;
		
		@include breakpoint(medium) {
			padding: 0 0 109px;
		}
		
		
		h2 {
			@include large-h2();
			margin-bottom: 20px;

			@media screen and (min-width: 640px) and (max-width: 1660px) {
				font-size: 37px;
			}
			
			a {
				color: inherit;
			}
		}
		
		h3 {
			@include price();
			
			em {
				font-size: 25px;
				font-style: normal;
			}
		}
		
		p {
			@include body-copy();
		}
		
		.multiple-buttons {
			text-align: left;
		}
	}
	
	&__item {
		margin-bottom: 40px;
		
		@include breakpoint(medium) {
			margin-bottom: 95px;
		}
		
		&:nth-child(odd) {
			.accommodation__slider {
				@include breakpoint(large) {
					margin-left: 8.3333%;
				}
			}
			
			.accommodation__content {
				@include breakpoint(medium) {
					padding-left: 60px;
				}
			}
		}
		
		&:nth-child(even) {
			.accommodation__slider {
				@include breakpoint(medium) {
					order: 2;

					.slick-prev {
						right: 96px;
						left: auto;
					}
	
					.slick-next {
						right: 0;
						left: auto;
					}
				}
			}
			
			.accommodation__content {
				@include breakpoint(medium) {
					order: 1;
					padding-left: 30px;
					padding-right: 30px;
				}
				
				@include breakpoint(large) {
					margin-left: 8.3333%;
				}
			}
		}
	}
	
	@include weddings-events() {
		.accommodation {
			&__content {
				.button {
					color: get-color('dark-heather');
					border-color: get-color('dark-heather');
				
					&:hover {
						color: #fff;
						background-color: get-color('dark-heather');
					}
				
					&--primary {
						color: #fff;
						background-color: get-color('dark-heather');
						border-color: get-color('dark-heather');
				
						&:hover {
							color: get-color('dark-heather');
							background-color: #fff;
						}
					}
				}
				
				.landing__header {
					&::after {
						background-image: url('img/wave-dark-heather.svg');
					}
					
					h2 {
						color: get-color('heather');
					}
				}
			}
		}
	}
}