.offer-detail {
    padding: 50px 0 70px;
    @include grey-gradient;
    position: relative;

    @include breakpoint(small only) {
        padding: 50px 0 120px
    }
    
    @include breakpoint(medium) {
        margin-top: -100px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url('img/small-bush-white.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: 285px;
        z-index: 0;
    }

    &__inner {
        z-index: 1;
    }

    &__features {
        z-index: 1;

        @include breakpoint(medium only) {
            padding-right: 20px;
        }
        
        @include breakpoint(small only) {
            margin-bottom: 50px;
        }

        &-title {

            h2 {
                @include large-h2;
                color: #fff;
                margin-bottom: 30px;
            }
        }

        &-list {

            ul {
                list-style: none;
                margin: 0;

                li {
                    position: relative;
                    margin-bottom: 30px;
                    padding-left: 30px;
                    font-weight: 700;
                    font-size: 15px;
                    letter-spacing: .025em;
                    line-height: 1.25;
                    color: #fff;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 0px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background-color: #C1C3C3;
                    }
                }
            }
        }

        &-price {

            h4 {
                color: #fff;
                font-size: 14px;
                line-height: 25px;
                font-weight: 500;
                letter-spacing: 0.075em;
                font-family: $primary-font;
                text-transform: uppercase;
                margin-bottom: 40px;

                span {
                    font-size: 24px;
                }
            }
        }
    }

    &__terms {
        z-index: 1;
        
        @include breakpoint(medium only) {
            padding-left: 20px;
        }

        &-title {

            h2 {
                @include large-h2;
                color: #fff;
                margin-bottom: 30px;
            }
        }

        &-text {

            p {
                @include body-copy;
                color: #fff;

                a {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
    
    
    
    
    @include spa() {
        @include blue-gradient();
        
        .offer-detail {
            &__features-cta .button--primary-white-outline {
                color: #fff;
                background-color: get-color('dark-blue');
                
                &:hover {
                    color: get-color('dark-blue');
                    background-color: #fff;
                }
            }
        }
    }
}