.gallery {
	margin-bottom: 130px;
	background-color: #fff;

	@include breakpoint(small only) {
		margin-bottom: 100px;
		margin-top: -50px;
	}
	
	&__grid {}
	
	&__item {
		width: 25%;
		float: left;
		padding: 5px;
		
		@include breakpoint(medium only) {
			width: 50%;
		}
		
		@include breakpoint(small only) {
			width: 100%;
			padding-bottom: 10px;
		}

        &.double-width {
            width: 50%;

            @include breakpoint(small down) {
                width: 100%;
            }
        }
		
		&-sizer {
			width: 25%;
			padding: 5px;
			
			@include breakpoint(medium only) {
				width: 50%;
			}
			
			@include breakpoint(small only) {
				width: 100%;
			}
		}
		
		a {
			position: relative;
			display: block;
			
			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				top: 10px;
				right: 10px;
				width: 16px;
				height: 16px;
				background: url('img/magnifying-glass-white.svg') no-repeat center center;
				background-size: 16px 16px;
			}
		}

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
	}
	
	&__modal {
        background-color: rgba(get-color('dark-grey'),0.95);
        padding-top: 60px;

        &-close {
            position: absolute;
            top: 30px;
            right: 8.3%;
            background: url('img/close-round-white.svg') no-repeat center center;
            background-size: 15px;
            width: 15px;
            height: 15px;
            text-indent: -12345px;

            @include breakpoint(small only) {
                right: 20px;
            }
        }

		&-image {
            background-color: #fff;
			position: relative;
			padding: 9px;
            margin-bottom: 10px;
			max-height: 81vh;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
		}
		
		&-prev,
		&-next {
			position: absolute;
			top: -20px;
			margin-top: -8px;
			width: 55px;
			height: 11px;
			text-indent: -12345px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 55px 11px;
			cursor: pointer;
		}
		
		&-prev {
			left: 40%;
			transform-origin: right center;
			background-image: url('img/slider-prev-white.svg');

            @include breakpoint(small only) {
                left: 30%;
            }
		}
		
		&-next {
            right: 40%;
			transform-origin: left center;
            background-image: url('img/slider-next-white.svg');

            @include breakpoint(small only) {
                right: 30%;
            }
		}
		
		&-prev,
		&-next {
			transition: transform 0.3s ease-in-out;
			
			&:hover,
			&:active {
				transform: scaleX(1.2);
			}
		}
		
		&-content {
			padding: 0 00px 15px;
			font-size: 12px;
            color: #fff;
		}
		
		&-caption {
            color: #fff;
			font-size: 15px;
			font-weight: 700;

			@include breakpoint(small only) {
				text-align: center;
				margin-bottom: 15px;
			}
		}
		
		&-cta {
			text-align: center;
			font-size: 16px;
			font-family: $primary-font;
			text-transform: uppercase;
			
			@include breakpoint(medium) {
				text-align: right;
			}

			
			.button, a {
				margin-left: 15px !important;

				@include breakpoint(small only) {
					margin-top: 20px;
				}
			}
		}
		
		&-iframe {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;
			
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}