.header {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	transition: background-color 0.3s ease-in-out;
	opacity: 0;
	
	@include breakpoint(small only) {
		box-shadow: 0 5px 5px rgba(#000, 0.2);
	}
	
	&.visible {
		opacity: 1;
	}
	
	&__inner {
		position: relative;
		width: 100%;
		padding: 21px 0;
		
		@include breakpoint(small only) {
			padding: 0;
		}
	}
	
	
	&__open-off-canvas {
		position: absolute;
		top: 45px;
		left: 50px;
		padding: 0 0 0 63px;
		color: get-color('dark-grey');
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		background: none transparent;
		line-height: 1;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			text-indent: -12345px;
			top: 22px;
			left: 20px;
		}
		
		&:hover,
		&:active {
			background: none transparent;
			
			rect {
				transform: scale(2.2, 1);
			}
		}
		
		svg {
			position: absolute;
			top: -4px;
			left: 0;
			width: 47px;
			height: 19px;
			overflow: hidden;
		}
		
		rect {
			fill: get-color('dark-grey');
			transition: all 0.3s ease-in-out;
		}
	}
	
	&__mobile-call {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 28px;
		height: 28px;
		text-indent: -12345px;
		margin: 0;
		padding: 0;
		background: none transparent;
		
		@media screen and (min-width: $offcanvas-breakpoint) {
			display: none;
		}
		
		&:hover,
		&:active {
			background-color: transparent;
		}
		
		img,
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		path {
			//fill: get-color('primary');
			transition: fill 0.3s ease-in-out;
		}
	}
	
	&__logo {
		width: 80%;
		max-width: 209px;
		margin: 0 auto;
		transition: all 0.3s ease-in-out;
		
		@include breakpoint(small only) {
			max-width: 186px;
		}
		
		&--transparent,
		&--scrolled {
			display: none;
		}
		
		img {
			width: 100%;
		}
	}

	&__top-nav {
		position: absolute;
		z-index: 500;
		transition: all 0.3s ease-in-out;
		
		&--left {
			top: 35px;
			left: 312px;
			
			li {
				display: inline;
				margin-right: 28px;
				
				@media screen and (max-width: $offcanvas-breakpoint) {
					display: none;
				}
			}
		}
		
		&--right {
			top: 24px;
			right: 50px;
			text-align: right;
			
			li {
				display: inline;
				margin-left: 28px;
				
				@media screen and (max-width: $offcanvas-breakpoint) {
					display: none;
				}
				
				&:last-child {
					@media screen and (max-width: $offcanvas-breakpoint) {
						display: inline;
					}
				}
			}
		}
		
		ul {
			margin: 0;
			list-style-type: none;
		}
		
		a {
			position: relative;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0.15em;
			text-transform: uppercase;
			transition: color 0.3s ease-in-out;
			padding-bottom: 10px;
			
			&::after {
				content: '';
				position: absolute;
				bottom: 0px;
				left: 0;
				width: 0;
				height: 5px;
				background: url('img/top-nav-line-dark-grey.svg');
				background-size: 67px 5px;
				transition: width 0.3s ease-in-out;
			}
			
			&:hover::after {
				width: 67px;
			}
		}
	}
	
	&__open-booking {
		min-width: 140px;
		border: 1px solid #fff;
		background-color: get-color('dark-grey');
		color: #fff;
		font-size: 13px !important;
		padding-bottom: 12px;
		padding-top: 11px;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			position: fixed !important;
			bottom: 0;
			width: 100%;
			left: 0;
			margin: 0;
			height: 43px;
			padding: 0;	
			line-height: 43px;
			border-width: 1px 0 0 0;
		}
		
		@media screen and (min-width: $offcanvas-breakpoint) {
			transform: translateY(8px);
		}
		
		&:hover,
		&:active {
			border-color: get-color('dark-grey');
			background-color: #fff;
			color: get-color('dark-grey');

		}

		&::after {
			content: none !important;
			width: 0px;
		}
	}
	
	&__social {
		position: absolute;
		top: 40px;
		left: 167px;
		transition: all 0.3s ease-in-out;
		
		@media screen and (max-width: $offcanvas-breakpoint) {
			display: none;
		}
		
		ul {
			margin: 0;
			list-style-type: none;
			
			li {
				display: inline;
				margin: 0 0 0 20px;
			}
		}
		
		path,
		circle {
			transition: fill 0.3s ease-in-out;
			fill: get-color('dark-grey');
		}
	}



	//////////////////////
	// Styles that are applied to only the transparent header
	//////////////////////
	&--transparent {
		background-color: transparent;
		box-shadow: none;
		
		.header {
			&__logo {
				&--transparent {
					display: block;
				}
				
				&--normal,
				&--scrolled {
					display: none;
				}
			}
			
			&__open-off-canvas {
				color: #fff;
				
				@media screen and (max-width: $offcanvas-breakpoint) {
					top: 26px;
				}
				
				rect {
					fill: #fff;
				}
			}
			
			&__top-nav {
				a:not(.button) {
					color: #fff;
					
					&:hover,
					&:active {
						color: #fff;
					}
					
					&::after {
						background-image: url('img/top-nav-line-white.svg');
					}
				}
			}
			
			&__open-booking:hover {
				color: get-color('green');
			}
			
			&__mobile-call {
				top: 22px;
				
				path {
					fill: #fff;
				}
			}
			
			&__social {
				path,
				circle {
					fill: #fff;
				}
			}
		}
	}
	
	
	&--scrolled {
		box-shadow: 0 5px 5px rgba(#000, 0.2);
		
		.header {
			&__inner {
				@include breakpoint(small only) {
					padding: 20px 0;
				}
			}
			
			&__logo {
				max-width: 105px;
				
				&--normal,
				&--transparent {
					display: none;
				}
				
				&--scrolled {
					display: block;
				}
			}
			
			&__open-off-canvas {
				@include breakpoint(medium) {
					top: 31px;
				}
			}
			
			&__top-nav--left {
				top: 22px;
			}
			
			&__top-nav--right {
				top: 8px;
			}

			&__social {
				top: 26px;
			}
		}
	}
	
	
	
	@include country-park() {
		.header__open-booking {
			color: #fff;
			border-color: #fff;
			background-color: get-color('mid-green');
	
			&:hover {
				color: get-color('mid-green');
				background-color: #fff;
				border-color: get-color('mid-green');
			}
		}
	}
	
	@include weddings-events() {
		.header__open-booking {
			color: #fff;
			border-color: #fff;
			background-color: get-color('dark-heather');
	
			&:hover {
				color: get-color('dark-heather');
				background-color: #fff;
				border-color: get-color('dark-heather');
			}
		}
	}
	
	@include spa() {
		.header__open-booking {
			color: #fff;
			border-color: #fff;
			background-color: get-color('dark-blue');
	
			&:hover {
				color: get-color('dark-blue');
				background-color: #fff;
				border-color: get-color('dark-blue');
			}
		}
	}
}






	