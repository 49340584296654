@mixin optional-at-root($sel) {
	  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	  }
	}
	
	@mixin placeholder {
	  @include optional-at-root('::-webkit-input-placeholder') {
		@content;
	  }
	
	  @include optional-at-root(':-moz-placeholder') {
		@content;
	  }
	
	  @include optional-at-root('::-moz-placeholder') {
		@content;
	  }
	
	  @include optional-at-root(':-ms-input-placeholder') {
		@content;
	  }
	}



/*
	Generates a thin arrow in the style of: < > ^ v
	The parent element requires absolute positioning
*/
@mixin arrow($direction: "right", $color: #000, $hover-color: #000) {
	&::before,
	&::after {
		content: '';
		position: absolute;
		
		background-color: $color;
		transition: all 0.3s ease-in-out;
		
		@if $direction == "left" or $direction == "right" {
			top: 50%;
			left: 0;
			right: 0;
			height: 2px;
			margin-top: -1px;
		} @else if $direction == "up" or $direction == "down" {
			left: 50%;
			top: 0;
			bottom: 0;
			width: 2px;
			margin-top: -1px;
		}
		
		@if $direction == "right" {
			transform-origin: right center;
		} @else if $direction == "left" {
			transform-origin: left center;
		} @else if $direction == "up" {
			transform-origin: center top;
		} @else if $direction == "down" {
			transform-origin: center bottom;
		}
	}
	
	&::before {
		@if $direction == "left" or $direction == "right" {
			transform: translateX(-10%) rotate(45deg);
		} @else if $direction == "up" or $direction == "down" {
			transform: translateY(-10%) rotate(45deg);
		}
	}
	
	&::after {
		@if $direction == "left" or $direction == "right" {
			transform: translateX(-10%) rotate(-45deg);
		} @else if $direction == "up" or $direction == "down" {
			transform: translateY(-10%) rotate(-45deg);
		}
	}
	
	&:hover,
	&:active {
		&::before,
		&::after {
			background-color: $hover-color;
		}
	}
}

@mixin flex-align-center {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

@mixin flex-align-bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
}


@mixin template-home {
	@each $complex in selector-parse(&) {
		@at-root body.template-home #{$complex} {
			@content;
		}
	}
}

@mixin spa {
	@each $complex in selector-parse(&) {
		@at-root body.theme-spa #{$complex} {
			@content;
		}
	}
}

@mixin weddings-events {
	@each $complex in selector-parse(&) {
		@at-root body.theme-weddings-events #{$complex} {
			@content;
		}
	}
}

@mixin country-park {
	@each $complex in selector-parse(&) {
		@at-root body.theme-country-park #{$complex} {
			@content;
		}
	}
}

@mixin grey-gradient {
	background: linear-gradient(0deg, rgba(157,157,157,1) 0%, rgba(90,90,90,1) 100%);
}

@mixin blue-gradient {
	background: linear-gradient(to bottom, get-color('dark-blue') 0%, get-color('blue') 100%);
}

@mixin light-blue-gradient {
	background: linear-gradient(to bottom, #fff 0%, rgba(get-color('light-blue'), 0.88) 100%);
}

@mixin heather-gradient {
	background: linear-gradient(to bottom, get-color('dark-heather') 0%, get-color('heather') 100%);
}

@mixin green-gradient {
	background: linear-gradient(to bottom, get-color('dark-green') 0%, get-color('green') 100%);
}