.intro-round-image {
	position: relative;
	padding: 20px 0 50px;
	background: linear-gradient(to bottom, get-color('blue') 0%, get-color('light-blue') 100%);
	
	@include breakpoint(medium) {
		padding-bottom: 25px;
		
		&::before {
			content: '';
			position: absolute;
			z-index: 10;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			pointer-events: none;
			background: url('img/estate-map-tree.svg') no-repeat left bottom;
		}
	}
	
	&__image {
		@include breakpoint(small only) {
			margin-bottom: 40px;
      padding: 20px 35px 0;
		}
		
		@include breakpoint(medium) {
			padding: 30px;
		}
		
		a {
			display: block;
			overflow: hidden;
			width: 100%;
			border-radius: 100%;
		}
	}
	
	&__content {
		color: #fff;
		@include flex-align-center();
		
		@include breakpoint(medium) {
      padding: 0 0 0 80px;
      text-align: left;
		}

    h1 {
      @include h1;
      margin-bottom: 0;
    }
		
		h2 {
			font-family: $tertiary-font;
      font-size: 40px;
			margin-bottom: 10px;
      
      @include breakpoint(medium) {
        font-size: 55px;
        margin-bottom: 10px;
      }
		}
		
		p {
			@include body-copy();
		}
	}
	
	@include weddings-events() {
		background: linear-gradient(to bottom, get-color('dark-heather') 0%, get-color('heather') 100%);
	
		.button.button--green-primary {
			background-color: get-color('dark-heather');
	
			&:hover {
				color: get-color('dark-heather');
				border-color: get-color('dark-heather');
				background-color: #fff;
			}
		}
	}
}

