.contact {
  position: relative;
  @include grey-gradient;
  padding: 40px 20px 110px;

  @include breakpoint(small only) {
    margin-top: -50px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 73px;
    width: 330px;
    height: 355px;
    background: url(img/home-country-park-bg.svg) right bottom no-repeat;
    background-size: contain;
    z-index: 0;
    pointer-events: none;
    opacity: 0.5;

    @include breakpoint(small only) {
      right: -81px;
      bottom: -59px;
    }
  }

  &__details {
    margin-bottom: 40px;
    padding-top: 40px;

    &-wrap {
      h4 {
        color: #fff;
        font-family: $primary-font;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.075em;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      ul {
        margin: 0 0 30px;
        list-style: none;

        li {
          color: #fff;
          @include body-copy;
          margin-bottom: 0px;

          a {
            color: #fff;
            @include body-copy;
          }
        }
      }

      p {
        color: #fff;
        @include body-copy;
        margin-bottom: 5px;

        a {
          color: #fff;
        }
      }
    }
  }

  &__form {
    &-field {
      margin-bottom: 25px;
      position: relative;
      text-align: left;

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        top: 0px;
        z-index: 1;

        ~ label {
          position: relative;
          padding-left: 28px;
          padding-top: 1px;
          cursor: pointer;
          line-height: 1 !important;
          display: block;
          @include smallest();
          font-size: 10px;
          color: #fff;
          font-weight: 700;

          &::before,
          &::after {
            content: "";
            position: absolute;
          }

          &::before {
            top: -3px;
            left: 0;
            width: 19px;
            height: 19px;
            border: 1px solid;
            border-radius: 50%;
            background-color: #fff;
          }

          &::after {
            content: "✓";
            color: #000;
            font-size: 12px;
            padding: 3px 0 0 1px;
            top: -4px;
            left: 4px;
            width: 14px;
            height: 14px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }

          a {
            color: inherit;
            text-decoration: underline;
            transition: opacity 0.3s ease-in-out;

            &:hover,
            &:active {
              opacity: 0.7;
            }
          }
        }

        &:checked ~ label {
          &:after {
            opacity: 1;
          }
        }
      }

      .button {
        min-width: unset;
        border-color: #fff;

        &:hover {
          border-color: get-color("dark-grey");
        }

        @include breakpoint(medium) {
          float: right;
        }
      }
    }

    button[type="submit"] {
      @include breakpoint(large) {
        width: 160px;
        height: 37px;
      }
    }

    .field-validation-error {
      color: #fff;
    }
  }

  @include spa() {
    @include blue-gradient();

    .contact {
      &__form button[type="submit"] {
        color: #fff;
        background-color: get-color("dark-blue");

        &:hover {
          color: get-color("dark-blue");
          background-color: #fff;
        }
      }
    }
  }

  @include weddings-events() {
    @include heather-gradient();

    .contact {
      &__form button[type="submit"] {
        color: #fff;
        background-color: get-color("dark-heather");

        &:hover {
          color: get-color("dark-heather");
          background-color: #fff;
        }
      }
    }
  }

  @include country-park() {
    @include green-gradient();

    .contact {
      &__form button[type="submit"] {
        color: #fff;
        background-color: get-color("mid-green");

        &:hover {
          color: get-color("mid-green");
          background-color: #fff;
        }
      }
    }
  }
}
